import { styled } from "styled-components";

export 

const DropdownWrapper = styled.div` 
    display: flex;
    flex-direction: column;

    label {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #344054;
        flex: none;
        order: 0;
        flex-grow: 0;
    }

    .dropdown {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 10px 14px;
        gap: 8px;
        width: 100%;
        height: 44px;
        background: #FFFFFF;
        border: 1px solid #D0D5DD;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 8px;
        flex: none;
        order: 1;
        align-self: stretch;
        flex-grow: 0;

        select {
            width: 100%;
            height: 100%;
            border: none;
            background: transparent;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #344054;
            appearance: none;
            outline: none;
            text-transform: capitalize;
            cursor: pointer;
        }
    }

    .icon {
      /* Estilos do ícone... */
      display: flex;
      align-items: center;
      margin-left: 8px;
    }

    &:disabled {
    opacity: 0.5;
    }
`;