import * as CD from "./CarDetails.style"
import Bank from "../../assets/svg/bank.svg"
import Car from "../../assets/svg/car.svg"
import { ValueFormatter } from '../../utils'
import Button from '../../components/Button/Button'
import { axiosWithAuth } from "../../services/axiosUtils"
import { useEffect, useState } from "react"
import { ImageZoom } from "../ImageZoom/ImageZoom"
import Modal from "../Modal/Modal"
import ConfirmReservation from "../Reservation/Reservation"

interface CarProps {
  marca?: string,
  model: string,
  year: number,
  value: number,
  history: string,
  reservaDisponivel: boolean,
  imagemPrincipal?: any
}

export default function CarDetails({ marca, model, year, value, history, reservaDisponivel, imagemPrincipal }: CarProps) {

  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleWindowClose = () => {
    setLoading(false)
  };

  const openNewWindow = (url: string) => {
    const windowFeatures = 'width=800,height=600'; // Dimensões da janela

    // Abre a nova janela com as dimensões definidas
    window.open(url, '_blank', windowFeatures)?.addEventListener("beforeunload", (ev) => {
      ev.preventDefault();
      handleWindowClose();
      console.log('iameter')
    });
  };

  const handleReservaClick = () => {
    setLoading(true)
    axiosWithAuth.post('/reserva/', { 'anuncio_id': localStorage.getItem('CardId') }).then((res: any) => {
      openNewWindow(res.data.payment_url);
    }).catch((err: any) => {
      console.log(err)
    })
      .finally(() => { setLoading(false) })
  }

  return (
    <CD.CardDetailWrapper>
      <CD.PhotoCard>
        <ImageZoom src={imagemPrincipal || Car} alt="" zoomLevel={2} />
      </CD.PhotoCard>
      <CD.CarDetails>
        <CD.CarPayment>
          <h1>{ValueFormatter(value)}</h1>
          <span>valor a vista</span>
        </CD.CarPayment>
        {reservaDisponivel && <>
          <CD.PageForm>
            <div className="wrapper">
              <i className="fa-solid fa-lock-open"></i>
              <span>Reserva disponível</span>
            </div>
            <Button onClick={() => setShowModal(true)}
              loading={loading}
              pulse={true}
              variant="primary"
              label="Reservar"
              loadingLabel="Reservando..."
              rightIcon={<i className="fa-solid fa-check" />}
            >
            </Button>
          </CD.PageForm>
        </>
        }
        <CD.CarModel>
          <h3>{marca} {model} - {year}</h3>
          <span>{history}</span>
        </CD.CarModel>
      </CD.CarDetails>
      <Modal style={{maxWidth: '350px'}} isOpen={showModal} onClose={() => {}}>
        <ConfirmReservation isLoading={loading} handlePagarClick={handleReservaClick} onClose={() => setShowModal(false)}/>
      </Modal>
    </CD.CardDetailWrapper>
  )
}