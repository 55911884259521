import { ReactNode } from "react";
import * as M from './Modal.styles'

interface ModalProps extends React.HTMLAttributes<HTMLDivElement> {
    isOpen: boolean;
    onClose: () => void;
    children: ReactNode;
}

export default function Modal({ isOpen, onClose, children, ...rest }: ModalProps) {
    if (!isOpen) return null;

    return (
        <M.ModalOverlay onClick={onClose}>
            <M.ModalContent {...rest} onClick={(e: any) => e.stopPropagation()}>
                {children}
            </M.ModalContent>
        </M.ModalOverlay>
    );
}