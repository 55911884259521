import styled from "styled-components";

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
`
export const LeftSide = styled.div`
  display: flex;
  gap:10px;
  align-items: center;
  
  &:hover {
    cursor: pointer;
  }
`
export const CenterSide = styled.div`

`
export const RightSide = styled.div`
  display: flex;
  gap:10px;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
`
interface ButtonPageProps {
  active: boolean;
}

export const ButtonPage = styled.button<ButtonPageProps>`
  text-align: center;
  background-color: ${props => props.active ? "#F9FAFB" : "white"};
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  cursor: pointer;
`