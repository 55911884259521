
import * as PU from './PopUpCard.styles'
import Cancel from '../CancelProcess/CancelProcess'
import Confirm from '../ConfirmationProcess/ConfirmationProcess'
import Reservation from '../Reservation/Reservation'


interface PopUpProps {
  operation: 'confirmation' | 'cancel' | 'reservation',
  type: 'seller' | 'buy',
  anounceId: number,
  onClose: () => void
}

export default function PopUpCard({ operation, type, anounceId, onClose }: PopUpProps) {

  return (
    <PU.PopUpWrapper>
      {(operation === 'confirmation') && <Confirm type={type} anounceId={anounceId} onClose={onClose} />}
      {(operation === 'cancel') && <Cancel onClose={onClose} anounceId={anounceId} type={type} />}
    </PU.PopUpWrapper>)
}