import warning from "../../assets/svg/warning.svg"
import close from "../../assets/svg/close.svg"
import * as CP from './CancelProcess.styles'
import Button from '../Button/Button'
import { useState } from "react"
import { axiosWithAuth } from "../../services/axiosUtils"
import { toast } from "react-toastify"

interface CancelProcess {
  type: string,
  anounceId: number,
  onClose: () => void
}

export default function CancelProcess({ type, anounceId, onClose }: CancelProcess) {
  const strings: any = {
    buy: "compra",
    seller: "venda"
  }
  const [isLoading, setLoading] = useState(false);
  const [description, setDescription] = useState('')

  const confirm = () => {
    setLoading(true)
    axiosWithAuth.post(`/reservas/${anounceId}/${type === 'buy' ? 'confirmar_comprador' : 'confirmar_vendedor'}/`, {
      "confirmacao": false,
      "justificativa": description
    }).then(({data}) => {
      toast.success('Obrigado pelas informações!')
    })
    .catch(err => {
      toast.error(err.response.data.error || "Erro ao salvar as informações. Por favor, tente mais tarde!")
      console.log(err.response.data)
    })
    .finally(() => {
      setLoading(false)
      onClose();
    });
  }

  return (
    <CP.CancelWrapper>
      <CP.TopCancel>
        <img src={warning} alt="Warning" />
        <img onClick={onClose} src={close} alt="Close" />
      </CP.TopCancel>
      <CP.MidCancel>
        <h1>Cancelar processo de {strings[type]}</h1>
        <span>Por favor, descreva abaixo o motivo do <br />cancelamento da {strings[type]} deste veículo.</span>
      </CP.MidCancel>
      <CP.DetailCancel>
        <span>Descrição detalhada</span>
        <textarea className="detailsCancel" name="Description" onChange={(e: any) => setDescription(e.target.value)} placeholder="Descrição rápida do não sucesso da venda" rows={3}></textarea>
      </CP.DetailCancel>
      <CP.BottomCancel>
        <Button onClick={onClose} variant="secondary" label="Cancelar" />
        <Button onClick={confirm} loading={isLoading} variant="primary" label="Enviar Solicitação" loadingLabel="Salvando..." />
      </CP.BottomCancel>
    </CP.CancelWrapper>
  )
}