import * as C from './ConfirmationProcess.styles'
import Check from '../../assets/svg/check.svg'
import Close from '../../assets/svg/close.svg'
import { useState } from 'react'
import { Rating } from 'react-simple-star-rating'
import Button from '../Button/Button'
import { axiosWithAuth } from '../../services/axiosUtils'
import { toast } from 'react-toastify'

export interface ConfirmProps {
  anounceId: number,
  onClose: () => void,
  type: 'buy' | 'seller'
}

export default function ConfirmationProcess({anounceId, type, onClose}: ConfirmProps) {
  const [ratingSeller, setRatingSeller] = useState(0)
  const [ratingKlickautos, setRatingKlickautos] = useState(0)
  const [ratingNeogciation, setRatingNegociation] = useState(0)
  const [isLoading, setLoading] = useState(false)

  const confirm = () => {
    setLoading(true)
    axiosWithAuth.post(`/reservas/${anounceId}/${type === 'buy' ? 'confirmar_comprador' : 'confirmar_vendedor'}/`, {
      "confirmacao": true,
    }).then(({data}) => {
      toast.success('Obrigado por avaliar!')
    })
    .catch(err => {
      toast.error(err.response.data.err || "Erro ao realizar avalição. Por favor, tente mais tarde!")
      console.log(err)
    })
    .finally(() => {
      setLoading(false);
      onClose();
    });
  }

  return (

    <C.ConfirmWrapper>
      <C.TopConfirm>
        <div className='main'>
          <img src={Check} alt='OK' />
        </div>
        <img onClick={onClose} src={Close} alt='fechar' className='absolute' />
      </C.TopConfirm>
      <C.MidConfirm>
        <h1>Processo concluído com sucesso!</h1>
        <span>Abaixo, deixe sua avaliação para a melhora de nossos serviços.</span>
      </C.MidConfirm>
      <C.RatingConfirm>
        <C.DetailConfirm>
          <div className='question'>
            <h3>Deu tudo certo?</h3>
            <span>Avalie de 1-5 de como o processo de negociação ocorreu.</span>
          </div>
          <div className='star'>
            <Rating
              onClick={setRatingNegociation}
            />
          </div>
        </C.DetailConfirm>
        <C.DetailConfirm>
          <div className='question'>
            <h3>Avalie a KlicAutos!</h3>
            <span>Avalie de 1-5 o quanto você indica a plataforma para outros compradores. </span>
          </div>
          <div className='star'>
            <Rating
              onClick={setRatingSeller}
            />
          </div>
        </C.DetailConfirm>
        <C.DetailConfirm>
          <div className='question'>
            <h3>Como você avalia o revendedor?</h3>
            <span>De 1-5 avalie o vendedor deste automóvel</span>
          </div>
          <div className='star'>
            <Rating
              onClick={setRatingKlickautos}
            />
          </div>
        </C.DetailConfirm>
        <hr className='linha' />
      </C.RatingConfirm>
      <C.BottomConfirm>
        <Button onClick={confirm} variant="primary" label="Enviar Avaliação" loading={isLoading} loadingLabel='Salvando...' />
      </C.BottomConfirm>
    </C.ConfirmWrapper>

  )
}
