import styled from "styled-components";

export const CardDetailWrapper = styled.div`
  display: flex;
  gap:50px;
  font-family: 'Inter', sans-serif;
  margin-top: 50px;
  max-height: 400px;
`

export const PhotoCard = styled.div`
    img{
      width: 689px;
      height: 380px;
      border-radius: 16px;
    }
`

export const CarDetails = styled.div`
    max-width:368px;
    flex-direction: column;
`
export const CarPayment = styled.div`
    margin-bottom: 100px;
    display: flex;
    justify-content: center;
    flex-direction: column;

  h1{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: xxx-large;
    text-align: right;
    line-height: 72px;
  } 
  span{
    display: flex;  
    justify-content: flex-end;
    text-align: right;
    color: #475467
  }
`

export const PageForm = styled.div`
  .wrapper {
    display: flex;
    flex-direction: column;

    i {
      color: #1570EF;
    }
  }
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  background: #F9FAFB;
  gap: 50px;
  padding: 8px;
  border-radius: 4px;
  img{
    padding: 0 18px;
  }
  span{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

`
export const CarModel = styled.div`
  h3{
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 38px;
    margin-bottom:18px;
  }
  span{
    width: 386px;
    white-space: pre-line;
  }
`
