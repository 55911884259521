import { styled } from "styled-components";

export const TableWrapper = styled.table `
  width: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px;
  background: #FFFFFF;
  border: 1px solid #EAECF0;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 12px;

  table {
    thead {
      tr {
        width: 176px;
        height: 44px;
      }
      
      th {
        padding-left: 24px;
        text-align: left;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #475467;
      }
    }

    tbody {
      
      tr {
        padding-left: 24px;
        width: 176px;
        height: 72px;
      }
      
      
      td {
        padding-left: 24px;
        text-align: left;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #475467;
      }
      
      td:first-child {
        font-weight: 500;
        color: #101828;
      }

      button {
        cursor: pointer;
      }

      button:first-child {
        margin-right: 12px;
        width: 85px;
        height: 36px;
        background: #0086C9;
        border: 1px solid #0086C9;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 8px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #FFFFFF;
      }
  
      button:last-child {
        width: 89px;
        height: 36px;
        background: #FFFFFF;
        border: 1px solid #D0D5DD;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 8px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #344054;
      }
    }
  }

    .evenRow {
      background-color: #EAECF0;
    }

    .oddRow {
      background-color: #FFFFFF;
    }
`