import { useState } from "react"
import * as C from "./Card.styles"
import RatingStar from "../RatingStars/RatingStars"
import avatar from "../../assets/svg/avatar.svg"
import Car from "../../assets/svg/car.svg"
import { ValueFormatter } from "../../utils"
import { useNavigate } from "react-router-dom"
import PriceTag from "../PriceTag/PriceTag"

export interface CardProps {
  name: string,
  model: string,
  year: number,
  value: number,
  rating: number,
  profileName: string,
  id: number,
  image: string,
  percentage: string,
  percentageType: string
}

export default function Card({ name, model, year, value, rating, profileName, id, image, percentage, percentageType }: CardProps) {
  const navigate = useNavigate();
  const toDetails = () => {
    localStorage.setItem("CardId", id.toString())
    navigate('/details')
  }
  return (
    <C.CardWrapper onClick={toDetails}>
      <C.Seller>
        <C.PersonaCard>
          <img src={avatar} alt="" />
          <span>{profileName}</span>
        </C.PersonaCard>
        <RatingStar rating={rating} />
      </C.Seller>
      <C.PhotoCar>
        <img src={image} alt="" />
      </C.PhotoCar>
      <C.DetailsCarWrapper>
        <C.DetailsCar>
          <span>{name}</span>
          <span>{model}</span>
          <span>{year}</span>
        </C.DetailsCar>

        <PriceTag isUp={percentageType == 'acima'} percentage={percentage} value={ValueFormatter(value)} />
      </C.DetailsCarWrapper>
    </C.CardWrapper>
  )
}
