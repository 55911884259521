import React from 'react';

export interface ImageZoomProps {
  src: string;
  alt?: string;
  zoomLevel?: number;
  onClick?: () => void;
}
export const ImageZoom: React.FC<ImageZoomProps> = ({
  src,
  alt = "",
  zoomLevel = 1,
  onClick
}) => {
  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const { offsetX, offsetY } = e.nativeEvent;
    const imageWidth = e.currentTarget.offsetWidth;
    const imageHeight = e.currentTarget.offsetHeight;
    const posX = (100 * offsetX) / imageWidth;
    const posY = (100 * offsetY) / imageHeight;

    e.currentTarget.style.backgroundPosition = `${posX}% ${posY}%`;
    e.currentTarget.style.backgroundSize = `${zoomLevel * 100}%`;
  };

  const handleMouseLeave = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.currentTarget.style.backgroundPosition = 'center';
    e.currentTarget.style.backgroundSize = 'cover';
  };

  return (
    <div 
      style={{
        backgroundImage: `url(${src})`,
        borderRadius: '20px',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        width: '100%',
        height: '100%',
        cursor: 'zoom-in'
      }}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      onClick={onClick} 
    >
      <img src={src} alt={alt} style={{ visibility: 'hidden' }} />
    </div>
  );
};
