import styled from "styled-components";

export const ConfirmWrapper = styled.div`
  font-family: 'Inter';
`

export const TopConfirm = styled.div`
    display: flex; 
    width: 100%;
    margin-bottom:32px; 
    .main{
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .absolute{
      margin-bottom:30px;
    }
`
export const MidConfirm = styled.div`
  margin:0 24px 20px;
  text-align: center;

  h1{
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 4px;
  }
  span{
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
  `

export const RatingConfirm = styled.div`
  .linha{
    width: 444px;
    height: 1px;
    background: #EAECF0;
    margin:0 -22px 22px;
    align-self: stretch;
  }
`

export const DetailConfirm = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom:12px;

  .question{
    width: 250px;
    padding-right: 10px;
    margin-bottom: 20px;
  }

  .star{
    display: flex;
    flex-direction: row;
    cursor: pointer;
    margin:2px;
    margin-bottom: 20px;
  }
  h3{
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #344054;
  }
  span{
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #475467;
  }

`

export const BottomConfirm = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

.confirm-button{
  padding: 10px 18px;
  gap: 8px;
  width: 352px;
  height: 44px;
  background: #1570EF;
  border: 1px solid #1570EF;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  color: #FFFFFF;
  font-size: 16px;
  line-height: 24px;
}
`