import styled from "styled-components";

export const NewClientWrapper = styled.div`
  font-family: 'Inter';
  width: 400px;
  padding: 24px;
`

export const TopNewCliente = styled.div`
    display: flex; 
    justify-content: space-between;
    width: 100%;
`
export const NewClient = styled.div`
  margin-bottom:32px;
`

export const MidNewCliente = styled.div`
    display: flex; 
    gap: 20px;
    margin:0  5px 20px 5px;

  h1{
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
  }
  
  label{
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;  
    margin-bottom: 5px;
  }
`

export const ContentNewDetailsVehicle = styled.div`
  margin-bottom: 10px;
  margin: 0 5px;

  .selectDetailsVehicle {
    display: flex;
    flex-direction: column;
    padding:10px 0;
  }

  .selectBrand{
    margin-right: 32px;
  }
  label{
    margin-bottom: 5px;
  }
    input {
    width: auto;
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-style: solid;
    border-radius: 4px;
    text-indent: 20px;
    padding: 10px 0;
  }
`

export const BottomNewClient = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 12px;
`
