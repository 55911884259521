import * as H from './Header.styles';
import { ReactComponent as Lupa } from '../../assets/svg/lupa.svg';
import { Input } from '../Input/Input';
import { ReactComponent as LArrow } from '../../assets/svg/leftarrow.svg';
import { getRazaoSocial } from '../../services/token';

type HeaderProps = {
  back?: boolean;
  welcomeText?: string;
  subText?: string;
};

export const Header = ({ back, welcomeText, subText }: HeaderProps) => {
  const pText = back ? 'Voltar' : welcomeText || `Bem vindo novamente, ${getRazaoSocial() || 'Carregando...'}`;
  const sText = back ? '' : subText || 'Os melhores carros do mercado em um só lugar';

  return (
    <H.HeaderWrapper>
      {back ? (
        <div className="back" onClick={() => window.history.back()}>
          <button>
            <LArrow />
          </button>
          <p>Voltar</p>
        </div>
      ) : (
        <div className="header">
          <div className="text">
            <p className="welcomeText">{pText}</p>
            <p className="subText">{sText}</p>
          </div>
          <H.InputWrapper>
            <Lupa className="lupaIcon" />
            <Input placeholder={'Busca'} type={'text'} />
          </H.InputWrapper>
        </div>
      )}
    </H.HeaderWrapper>
  );
};