import React from 'react';

import CreatableSelect from 'react-select/creatable';
import { StylesConfig } from 'react-select';
import { optionLength } from './data';

interface Props {
    options: any;
    isLoading: boolean;
    onChange: any;
    value: any
}

const customStyles: StylesConfig = {
    control: (provided) => ({
        ...provided,
        // width: '200px', // Defina a largura desejada aqui
        width: '100%',
    }),
};

export default ({ options, isLoading, onChange, value }: Props) => <CreatableSelect
    placeholder="Selecione"
    isClearable
    options={options}
    noOptionsMessage={() => 'Nenhuma opção encontrada'}
    isLoading={isLoading}
    onChange={onChange}
    value={value}
    styles={customStyles} />;
