import { useState, FC, CSSProperties } from 'react';
import * as F from './Filter.styles';
import Button from '../Button/Button';
import { useNavigate } from 'react-router-dom';

interface FilterProps {
  filterOptions: {
    label: string;
    onClick: () => void;
  }[];
  addButton?: boolean;
  labelButton?: string;
  handleButton?: () => void;
  buttonStyles?: React.CSSProperties;
}

export const Filter: FC<FilterProps> = ({ filterOptions,
                                            buttonStyles,
                                            addButton,
                                            labelButton,
                                            handleButton
                                        }) => {
  const [activeButton, setActiveButton] = useState<string>(filterOptions[0]?.label || 'Carregando...');
  const navigate = useNavigate();
  const handleClick = (buttonName: string, onClick: any) => {
    setActiveButton(buttonName);
    onClick();
  };

  return (
    <F.FilterWrapper>
      <F.FilterButton>
        {filterOptions.map(({label, onClick}) => (
          <button
            style={buttonStyles}
            className={activeButton === label ? 'active' : ''}
            onClick={() => handleClick(label, onClick)}
          >
            {label}
          </button>
        ))}
      </F.FilterButton>
      <F.NovoAnuncioWrapper>
          { addButton &&
              <Button onClick={handleButton} variant='primary' label={labelButton}/>
          }
      </F.NovoAnuncioWrapper>
    </F.FilterWrapper>
  );
};
