import styled from "styled-components";

export const HeaderWrapper = styled.header`
  margin-bottom: 20px;

  .back {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 0px;
    cursor: pointer;
  }

  .back button {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 8px;
    gap: 8px;
    width: 36px;
    height: 36px;
    background: #FFFFFF;
    border: 1px solid #D0D5DD;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
  }

  .back p {
    width: 45px;
    height: 24px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
  }

  .back button:hover {
    box-sizing: border-box;
    padding: 8px;
    gap: 8px;
    position: absolute;
    width: 36px;
    height: 36px;
    background: #F6FAFB;
  }

  .header {
    display: flex;
    justify-content: space-between;
  }

  p {
    margin: 0px;
  }
  
  .text {
    display: flex;
    flex-direction: column;
  }

  .welcomeText {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #101828;
    text-align: left;
    margin-bottom: 4px;
  }
  
  .subtext {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #475467;
    text-align: left;
  }
`

export const InputWrapper = styled.div`
  position: relative;
  display: inline-block;

  .lupaIcon {
    position: absolute;
    top: 2.8rem;
    left: 10.5px;
    transform: translateY(-205%);
    width: 16px;
    height: 16px;
    padding-left: 4px;
  }

  input {
    padding: 10px 14px 10px 35px;
    width: 320px;
    height: 44px;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  }

  input::placeholder {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

`