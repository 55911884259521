import * as P from './Pagination.styles';
import LArrow from '../../assets/svg/leftarrow.svg'
import RArrow from '../../assets/svg/rightarrow.svg'

interface PaginationProps {
  totalPages: number,
  actualPage: number,
}

export default function Pagination({ totalPages, actualPage }: PaginationProps) {
  const ButtonGeneration = (totalPages: number, actualPage: number) => {
    const buttons = []
    for (let i = 1; i < totalPages; i++) {
      buttons.push(<P.ButtonPage active={i === actualPage}>{i}</P.ButtonPage>)
    }
    return buttons
  }
  return (
    <P.PaginationWrapper>
      <P.LeftSide>
        <img src={LArrow} alt="" />
        <span>Anterior</span>
      </P.LeftSide>
      <P.CenterSide>
        {ButtonGeneration(totalPages, actualPage)}
      </P.CenterSide>
      <P.RightSide>
        <span>Próxima</span>
        <img src={RArrow} alt="" />
      </P.RightSide>
    </P.PaginationWrapper>
  )
}