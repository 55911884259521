import styled from "styled-components";

export const SidebarWrapper = styled.div`
  width: 109px;
  height: 100vh;
  background-color: #FFFF;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-right: 1px solid #EAECF0;
  gap: 16px;
  
.logo {
  margin: 20px 0px 40px 0px;
  width: 80px;
  height: 66px;
  cursor: pointer;
}

svg {
  background-color: transparent;
}

.avatar {
  width: 48px;
  height: 48px;
  background: #F2F4F7;
  border-radius: 50%;
  margin: auto 30px 23px 31px;
}
`

export const SidebarButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;    
  gap: 4px;
  div {
    width: 47px;
    height: 47px;
    background: #FFFF;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;    
    border-radius: 10px;
    transition: .2s ease-in-out;
  }

  div:hover {
    width: 47px;
    height: 47px;
    background: #101828;
    border: 0px;
    color: white;
  }

  .active {
    background: #101828;
    color: white;
    border: 0px;
  }

  svg {
    height: 24px;
    width: 24px;
   }
   i {
    font-size: 18px;
   }
`