import React, { useState } from "react";
import * as R from './Register.styles'
import { Input } from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import StatusBar from "../../components/StatusBar/StatusBar";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { axiosWithoutAuth } from "../../services/axiosUtils";
import Dropdown from "../../components/Dropdown/Dropdown";

export default function Register() {

    const navigate = useNavigate();

    const [step, setStep] = useState(0);
    const [tipos] = useState([
        { value: 'lojista', label: 'lojista' },
        { value: 'cliente', label: 'cliente'},
        { value: 'corretor', label: 'corretor' },
    ]);

    const [tipoUsuarioSelecionado, setTipoUsuarioSelecionado] = useState('lojista');

    const [defaultInfos, setDefaultInfos] = useState({
        "first_name": '',
        "last_name": '',
        'username': '',
        "email": '',
        "password": '',
        'endereco': {},
        'info_empresa': {},
        'tipo': ''
    });

    const [info_empresa, setInfoEmpresa] = useState({
        "CNPJ": 0,
        "razao_social": ''
    });

    const [endereco, setEndereco] = useState({
        "rua": '',
        "cep": 0,
        "cidade": '',
        "bairro": '',
        "estado": ''
    });

    const handleInputDefaultInfos = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setDefaultInfos((prevForm) => ({
            ...prevForm,
            [name]: value,
        }));
    };

    const handleInputInfoEmpresa = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setInfoEmpresa((prevForm) => ({
            ...prevForm,
            [name]: value,
        }));
    };


    const handleInputEndreco = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setEndereco((prevForm) => ({
            ...prevForm,
            [name]: value,
        }));
    };

    const backStep = () => {
        setStep(step - 1);
    }

    const nextStep = () => {
        setStep(step + 1);
    }

    function validateFirstName(firstName: string) {
        if (!firstName || firstName.length <= 3) {
            return 'O campo Nome Completo é obrigatório';
        }
        if (!/^[a-zA-Z]+$/.test(firstName)) {
            return 'O campo Nome Completo deve conter apenas letras';
        }
        return null;
    }

    function validateLastName(lastName: string) {
        if (!lastName || lastName.length <= 3) {
            return 'O campo Sobrenome é obrigatório';
        }
        if (!/^[a-zA-Z]+$/.test(lastName)) {
            return 'O campo Sobrenome deve conter apenas letras';
        }
        return null;
    }

    function validateInfoEmpresa(infoEmpresa: any) {
        if (!infoEmpresa.CNPJ || infoEmpresa.CNPJ.length === 13) {
            return 'O campo CNPJ é obrigatório';
        }
        if (!infoEmpresa.razao_social) {
            return 'O campo Razão Social é obrigatório';
        }
        return null;
    }

    function validateEndereco(endereco: any) {
        if (!endereco.rua) {
            return 'O campo Rua é obrigatório';
        }
        if (!endereco.cep || endereco.cep.length >= 7) {
            return 'O campo CEP é obrigatório';
        }
        if (!endereco.cidade) {
            return 'O campo Cidade é obrigatório';
        }
        if (!endereco.bairro) {
            return 'O campo Bairro é obrigatório';
        }
        if (!endereco.estado) {
            return 'O campo Estado é obrigatório';
        }
        return null;
    }

    function validateUsername(username: string) {
        if (!username) {
            return 'O campo Nome de Usuário é obrigatório';
        }
        if (username.length < 3) {
            return 'O campo Nome de Usuário deve ter pelo menos 3 caracteres';
        }
        return null;

    }
    const doRegister = () => {
        var def = defaultInfos;
        const names = def.first_name.split(' ');
        const firstName = names[0];
        const lastName = names.slice(1).join(' ');
        def['first_name'] = firstName;
        def['last_name'] = lastName;
        def['info_empresa'] = info_empresa;
        def['endereco'] = endereco;
        def['username'] = info_empresa['CNPJ'].toString();
        def['tipo'] = 'lojista';
       
        axiosWithoutAuth.post('/registro/', def)
            .then((response: any) => {
                toast.success('Cadastro realizado com sucesso!');
                navigate('/');
            })
            .catch((error: any) => {
                toast.error('Erro ao realizar cadastro! ' + error.response.data.error);
            })
            .finally(() => { });;


    }

    return (
        <R.PageContent>
            <h1>Cadastre-se</h1>
            <h2>Abaixo, os dois passos necessários para o registro</h2>
            <R.RegisterWrapper>
                <R.LeftContainer>
                    <R.LeftContent>
                        <R.PersonalInput>
                            <Input name={'first_name'} onChange={handleInputDefaultInfos} label={'Nome Completo'} placeholder='Nome completo' />
                            <Input name={'CNPJ'} onChange={handleInputInfoEmpresa} label={'CNPJ'} placeholder='CNPJ' />
                            <Input name={'password'} onChange={handleInputDefaultInfos} label={'Senha'} type={'password'} placeholder='Senha' />
                            <Input name={'razao_social'} onChange={handleInputInfoEmpresa} label={'Razão Social'} placeholder='Razão Social' />
                            <Input name={'email'} label={'Email'} onChange={handleInputDefaultInfos} placeholder='E-mail' />
                            <Dropdown label="Tipo de Usuario" options={tipos} onChange={setTipoUsuarioSelecionado} value={tipoUsuarioSelecionado}></Dropdown>
                        </R.PersonalInput>
                        <Button disabled={step !== 0} variant="primary" label={'Próximo Passo'} onClick={() => nextStep()} />
                        <StatusBar active={step >= 0} title={'Dados Pessoais'} description={'Preencha seus dados pessoais'} />
                    </R.LeftContent>
                </R.LeftContainer>
                <R.RightContainer>
                    <R.RightContent>
                        <R.PersonalInput>
                            <Input disabled={step !== 1} name={'rua'} onChange={handleInputEndreco} label={'Rua'} placeholder='Rua' />
                            <Input disabled={step !== 1} name={'cep'} onChange={handleInputEndreco} label={'CEP'} placeholder='00000-000' />
                            <Input disabled={step !== 1} name={'cidade'} onChange={handleInputEndreco} label={'Cidade'} placeholder='Cidade' />
                            <Input disabled={step !== 1} name={'bairro'} onChange={handleInputEndreco} label={'Bairro'} placeholder='Bairro' />
                            <Input disabled={step !== 1} name={'estado'} onChange={handleInputEndreco} label={'Estado'} placeholder='Estado' />
                        </R.PersonalInput>
                        <R.Actions>
                            <Button disabled={step !== 1} variant={'secondary'} label={'Passo Anterior'} onClick={backStep} />
                            <Button disabled={step !== 1} variant='primary' label={'Concluir Cadastro'} onClick={doRegister} />
                        </R.Actions>
                        <StatusBar active={step === 1} title={'Endereço'} description={'Preencha seu endereço'} />
                    </R.RightContent>
                </R.RightContainer>
            </R.RegisterWrapper>
        </R.PageContent>
    )
}