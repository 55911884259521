import styled from "styled-components";

export const HomeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #FFFF;
  font-family: 'Inter', sans-serif;
  
`

export const MainContainer = styled.div`
  display: flex;
  flex-grow: 1;

  aside {
    height: 100%;
  }

  section {
    padding: 20px 20px 0;
    flex-grow: 1;
    display: flex;
    max-height: 100vh;
    flex-direction: column;
    /* overflow: auto; */
  }

  hr {
    width: 100%;
    margin: 20px 0px 2px 0px;
    border-top: 1px solid #EAECF0;
  }

`

export const MainContent = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .central {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    padding-top: 16px;
    gap: 16px;
  }

  .central.loading {
    justify-content: center;
    align-items: center;
  }
  
  .pagination {
    height: 100px;
    border-top: 1px solid #EAECF0;
    display: flex;
    align-items: center;
  }

  .central.column {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    flex-direction: column;
    }

  .veiculo {
    display: flex;
    flex-direction: column;

    span {
      text-transform: capitalize;
    }
    span:first-child {
      color: #101828;
      font-weight: 400;
    }
  }
`