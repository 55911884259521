import styled from "styled-components";

export const ReservationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: 'Inter';
`


export const TopReservation = styled.div`
    display: flex; 
    width: 100%;
    margin-bottom:32px; 
    .main{
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .absolute{
      margin-bottom:30px;
    }
`


export const MidReservation = styled.div`
  margin-bottom:20px;

  h1{
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 4px;
  }
  span{
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
  `

export const BottomReservation = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 12px;
`