import * as H from '../Home/Home.styles';
import * as L from '../List/List.styles'
import { Sidebar } from '../../components/Sidebar/Sidebar';
import { Header } from '../../components/Header/Header';
import { Filter } from '../../components/Filter/Filter';
import { Table } from '../../components/Table/Table';
import { useEffect, useState } from 'react';
import Pagination from '../../components/Pagination/Pagination';
import Tag from '../../components/Tag/Tag';
import Modal from '../../components/Modal/Modal';
import PopUpCard from '../../components/PopUpCard/PopUpCard';
import { axiosWithAuth } from '../../services/axiosUtils';

export interface Data {
  id: number;
  data: Date;
  status: string;
  anuncio: {
    marca: string;
    modelo: string;
    ano: number;
    user: {
      razao_social: string;
    };
  };
  user: {
    razao_social: string;
  };
}

export type Columns = {
  id: string;
  Header: string;
  accessor: (row: Data) => JSX.Element | string;
}[];

export default function List() {
  const [data, setData] = useState<Data[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [anounceId, setAnounceId] = useState(0);
  const [operation, setOperation] = useState<'confirmation' | 'cancel'>('confirmation');
  const [type, setType] = useState<'seller' | 'buy'>('buy');

  const confirmar = (id: number) => {
    setAnounceId(id);
    setOperation('confirmation');
    setShowModal(true);
  }

  const cancelar = (id: number) => {
    setAnounceId(id);
    setOperation('cancel');
    setShowModal(true);
  }

  const columns: Columns = [
    {
      id: 'pedido',
      Header: 'Pedido',
      accessor: (row: Data) => (
        <div className='pedidos'>
          <b>#{row.id}</b>
        </div>
      ),
    },
    {
      id: 'data',
      Header: 'Data',
      accessor: (row: Data) => row.data.toLocaleDateString('pt-BR', { year: 'numeric', month: 'short', day: 'numeric' }),
    },
    {
      id: 'status',
      Header: 'Status',
      accessor: (row: Data) => {
        const status = row.status.split('_')[0] || row.status;
        return <Tag type={status as 'finalizado' | 'pendente' | 'aguardando' | 'cancelado'} text={row.status} />;
      },
    },
    {
      id: 'veiculo',
      Header: 'Veiculo',
      accessor: (row: Data) => (
        <div className='veiculo'>
          <span>{row.anuncio.modelo} {row.anuncio.ano}</span>
          <span>{row.anuncio.user.razao_social}</span>
        </div>
      ),
    },
    {
      id: 'conclusao_do_processo',
      Header: 'Conclusão do processo',
      accessor: (row: Data) => {
        const status = row.status.split('_')[0] || row.status;
        if (status === 'em' || status === 'finalizado' || (row.status === 'aguardando_vendedor' && type === 'seller') || (row.status === 'aguardando_comprador' && type !== 'buy')) 
          return <Tag type={status as 'finalizado' | 'pendente' | 'aguardando' | 'cancelado'} text={row.status} />;
        return (
          <div>
            <button onClick={() => confirmar(row.id)}>Concluir</button>
            <button onClick={() => cancelar(row.id)}>Cancelar</button>
          </div>
        );
      },
    },
  ];
  
  const getList = () => {
    axiosWithAuth.get('/reservas/')
    .then(({data}) => {
      setData(data.content.map((item: any) => ({
        id: item.id,
        data: new Date(item.criado_em),
        status: item.status,
        anuncio: {
          marca: item.anuncio.marca,
          modelo: item.anuncio.modelo,
          ano: item.anuncio.ano,
          user: {
            razao_social: item.anuncio.user.info_empresa.razao_social,
          },
        },
        user: {
          razao_social: item.user.info_empresa.razao_social,
        }
      })));
    })
    .catch(err => console.log(err))
    .finally(() => {});
  }

  const getListSeller = () => {
    axiosWithAuth.get('/reservas/vendas/')
    .then(({data}) => {
      setData(data.content.map((item: any) => ({
        id: item.id,
        data: new Date(item.criado_em),
        status: item.status,
        anuncio: {
          marca: item.anuncio.marca,
          modelo: item.anuncio.modelo,
          ano: item.anuncio.ano,
          user: {
            razao_social: item.anuncio.user.info_empresa.razao_social,
          },
        },
        user: {
          razao_social: item.user.info_empresa.razao_social,
        }
      })));
    })
    .catch(err => console.log(err))
    .finally(() => {});
  }

  useEffect(() => {
    getList();
  }, []);

  useEffect(() => {
    if (!showModal) {
      if (type === 'buy') {
        getList();
      } else {
        getListSeller();
      }
    }
  }, [showModal]);

  const filterButtonStyling = {
    width: '136px',
  };

  const options = [
    { label: 'Minhas Compras', onClick: () => {
      getList();
      setType('buy');
    } },
    { label: 'Minhas Vendas', onClick: () => {
      getListSeller();
      setType('seller');
    } }
  ];

  return (
    <>
      <L.ListWrapper>
        <H.HomeWrapper>
          <H.MainContainer>
            <aside>
              <Sidebar></Sidebar>
            </aside>
            <section>
              <Header></Header>
              <Filter filterOptions={options} buttonStyles={filterButtonStyling} />
              <hr></hr>
              <H.MainContent>
                {data.length > 0 ? <Table columns={columns} data={data} /> : 'Você não possui reservas.'}
                <div className="pagination">
                  <Pagination totalPages={1} actualPage={1} />
                </div>
              </H.MainContent>
            </section>
          </H.MainContainer>
        </H.HomeWrapper>
      </L.ListWrapper>
      <Modal isOpen={showModal} onClose={() => {}}>
        <PopUpCard operation={operation} type={type} anounceId={anounceId} onClose={() => setShowModal(false)}/>
      </Modal>
    </>
  );
}
