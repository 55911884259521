import React, { useState } from "react";
import * as D from "./Dropdown.styles";
import { BsChevronDown } from "react-icons/bs"; // Importe o ícone de seta


interface Option {
  value: string;
  label: string;
}

interface DropdownProps {
  label: string;
  options: Option[];
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
}

const Dropdown: React.FC<DropdownProps> = ({
  label,
  options,
  value,
  onChange,
  disabled,
}) => {

  const handleDropdownChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    onChange(event.target.value);
  };

  return (
    <D.DropdownWrapper>
      <label>{label}</label>
      <div className="dropdown">
        <select
          value={value}
          onChange={handleDropdownChange}
          disabled={disabled}
        >
          {options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <div className="icon">
          <BsChevronDown />
        </div>
      </div>
    </D.DropdownWrapper>
  );
};

export default Dropdown;