// import styled from "styled-components";
import * as H from './Home.styles';
import { Sidebar } from '../../components/Sidebar/Sidebar';
import { Header } from '../../components/Header/Header';
import Card from '../../components/Card/Card'
import Pagination from '../../components/Pagination/Pagination';
import { useEffect, useState } from 'react';
import { Filter } from '../../components/Filter/Filter';
import { axiosWithAuth } from '../../services/axiosUtils';
import ReactLoading from 'react-loading';
// import { get } from 'https';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

export default function Home() {

  const [isLoading, setLoading] = useState(false);
  const [cards, setCards] = useState<any>();
  const [tryGetAnuncios, setTryGetAnuncios] = useState<number>(0);
  const navigate = useNavigate();
  const convertModel = (data: any[]) => {
    return data.map(item => ({
      name: item.marca,
      model: item.modelo,
      year: item.ano,
      value: item.valor,
      rating: item.rating || 4,
      percentage: item.porcentagem || '0',
      percentageType: item.porcentagem_tipo || 'acima',
      profileName: item.profile_name || 'Revendedor',
      imagens: item.imagens,
      id: item.id
    }));
  }

  const getAnuncios = () => {
    localStorage.removeItem('CardId');
    setLoading(true);
    axiosWithAuth.get('/anuncios/').then((response: any) => {
      const cards_mock = {
        content: convertModel(response.data),
        page: 1,
        total_pages: 1,
      }
      setCards(cards_mock);
    }).catch((err: any) => {
      if(tryGetAnuncios < 3) {
        getAnuncios()
        setTryGetAnuncios(tryGetAnuncios + 1);
      } else {
        toast.error('Erro ao carregar anúncios');
      }
      console.log(err);
    })
    .finally(() => setLoading(false));
  }

  useEffect(() => {
    const token = localStorage.getItem('token');
    if(token)
      getAnuncios();
    else
      window.location.reload();
  }, []);

  const options = [
    { label: 'Mais vistos', onClick: () => { } },
    { label: 'Novos', onClick: () => { } },
    { label: 'Seminovos', onClick: () => { } },
    { label: 'Recentes', onClick: () => { } }
  ];

  return (
    <H.HomeWrapper>
      <H.MainContainer>
        <aside>
          <Sidebar></Sidebar>
        </aside>
        <section>
          <Header></Header>
          <Filter filterOptions={options} addButton={true} labelButton={'Novo anuncio'} handleButton={() => navigate('/new')}></Filter>
          <hr></hr>
          <H.MainContent>
            <div className={`central ${isLoading || cards?.content?.length == 0 ? 'loading' : ''}`} >
              {isLoading ? <ReactLoading type="spin" color="black" height={32} width={32} /> : '' ||

                cards?.content?.length == 0 ?
                <h4>Nenhum resultado encontrado</h4>
                :
                cards?.content?.map((card: any) => <Card name={card?.name}
                  model={card?.model}
                  percentage={card?.percentage}
                  percentageType={card?.percentageType}
                  year={card?.year}
                  key={card?.id}
                  value={card?.value}
                  rating={card?.rating}
                  profileName={card?.profileName}
                  image={card?.imagens && card?.imagens.length > 0 ? card?.imagens[0]?.imagem : ''}
                  id={card?.id}
                />
                )}
            </div>
            <div className="pagination">
              <Pagination totalPages={cards?.total_pages || 1} actualPage={cards?.page || 1} />
            </div>
          </H.MainContent>
        </section>
      </H.MainContainer>
    </H.HomeWrapper>
  );
}