import styled from "styled-components";

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
  padding: 20px 16px;
  gap: 10px;
  border: 1px solid #EAECF0;
  border-radius: 24px;
  max-width: 300px;
  height: 300px;
  cursor: pointer;
`
export const Seller = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`


export const PersonaCard = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  span{
    padding-left: 8px;
  }

  img {
     width: 100%;
    object-fit: cover;
  }
`

export const PhotoCar = styled.div`
  display: flex;
  justify-content: center;
  overflow: hidden;

  img {
     width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
  }
`

export const DetailsCarWrapper = styled.div`
  display: flex;
  justify-content: space-between;

`

export const DetailsCar = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    span{
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      /* font-size: 14px; */
      
    }
    span:not(:first-child){
      font-size: 12px;
    }
`