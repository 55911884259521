import React, { ChangeEvent, FormEvent, ReactComponentElement, useEffect, useRef, useState } from 'react';
import * as FVR from '../FormVehicleRegister/FormVehicleRegister.styles';
import MiniCar from '../../assets/svg/minicar.svg'
import { NumericFormat } from 'react-number-format';
import Button from '../Button/Button';
import { axiosWithAuth } from '../../services/axiosUtils';
import { toast } from 'react-toastify';
import { error } from 'console';
import { getUserId } from '../../services/token';
import { useNavigate } from 'react-router-dom';
import { API_FIPE } from '../../envs';
import SelectInput from '../SelectInput/SelectInput';
import { ComparePercentageValue, ValueFormatter, ConvertStringToNumber, getTypePercentage } from '../../utils';

const inputs = [
  { label: "Título do anúncio", type: "text", id: "titulo", name: "titulo", placeholder: "", value: "" },
  { label: "Valor do carro", type: "number", id: "valor", name: "valor", placeholder: "R$ BRL", value: "" },
];


interface MarcaModeloProps {
  nome: string;
  codigo: number;
}


export default function FormVehicleRegister() {

  const fotoPPhotoRef = useRef<any>(null)
  const fotoLEPhotoRef = useRef<any>(null)
  const fotoLDPhotoRef = useRef<any>(null)
  const fotoTraseiraPhotoRef = useRef<any>(null)
  const [pictures, setPictures] = useState<any>({});
  const [marcaSelecionada, setMarcaSelecionada] = useState<any>();
  const [marcas, setMarcas] = useState<MarcaModeloProps[]>([]);
  const [marcasLoading, setMarcasLoading] = useState<boolean>(false);

  const [modeloSelecionada, setModeloSelecionada] = useState<any>();
  const [modelos, setModelos] = useState<MarcaModeloProps[]>([]);
  const [modeloLoading, setModeloLoading] = useState<boolean>(false);

  const [anoSelecionada, setAnoSelecionada] = useState<any>();
  const [anos, setAnos] = useState<MarcaModeloProps[]>([]);
  const [anosLoading, setAnosLoading] = useState<boolean>(false);

  const [percentage, setPercentage] = useState<string>();
  const [percentageType, setPercentageType] = useState<string>();


  const [fipeValue, setFipeValue] = useState<any>();

  const navigate = useNavigate();


  const [formData, setFormData] = useState({
    titulo: '',
    valor: '',
    marca: '',
    modelo: '',
    ano: '2015',
    descricao: '',
    fotoP: null,
    fotoLE: null,
    fotoLD: null,
    fotoTraseira: null,
  });

  const getMarcas = () => {
    setMarcasLoading(true)
    axiosWithAuth
      .get(`${API_FIPE}/marcas/`)
      .then(({ data }) => {
        setMarcas(data.map((marca: any) => ({ label: marca.nome, id: marca.codigo })));
      })
      .catch((err) => {
        toast.error('Erro ao buscar marcas');
      }).finally(() => setMarcasLoading(false));
  }

  const getModelos = () => {
    setModeloLoading(true)
    axiosWithAuth
      .get(`${API_FIPE}/marcas/${marcaSelecionada.id}/modelos/`)
      .then(({ data }) => {
        setModelos(data.map((modelo: any) => ({ label: modelo.nome, id: modelo.codigo })));
      })
      .catch((err) => {
        toast.error('Erro ao buscar modelos');
      }).finally(() => setModeloLoading(false));
  }

  useEffect(() => {
    if (marcaSelecionada && marcaSelecionada.id) {
      getModelos();
    }
  }, [marcaSelecionada])

  const getAnos = () => {
    setAnosLoading(true)
    axiosWithAuth
      .get(`${API_FIPE}/marcas/${marcaSelecionada.id}/modelos/${modeloSelecionada.id}/anos/`)
      .then(({ data }) => {
        setAnos(data.map((modelo: any) => ({ label: modelo.nome, id: modelo.codigo })));
      })
      .catch((err) => {
        toast.error('Erro ao buscar modelos');
      }).finally(() => setAnosLoading(false));
  }

  const getFipe = () => {
    setAnosLoading(true)
    axiosWithAuth
      .get(`${API_FIPE}/marcas/${marcaSelecionada.id}/modelos/${modeloSelecionada.id}/anos/${anoSelecionada.id}`)
      .then(({ data }) => {
        setFipeValue(data);
      })
      .catch((err) => {
        toast.error('Erro ao buscar fipe');
      }).finally(() => setAnosLoading(false));
  }

  useEffect(() => {
    if (anoSelecionada && anoSelecionada.id) {
      getFipe();
    }
  }, [anoSelecionada])

  useEffect(() => {
    if (modeloSelecionada && modeloSelecionada.id) {
      getAnos();
    }
  }, [modeloSelecionada])

  const handlePhotoInput = (valueRef: any) => {
    valueRef?.current?.click();
  }

  const handleChangeInput = (event: any, typeOrigin: string) => {
    if (!event?.target?.files || !event.target.files[0]) return false;
    const file = event.target.files[0]
    const reader = new FileReader();
    reader.onloadend = () => {
      setPictures({ ...pictures, [typeOrigin]: reader.result as string });
    };
    reader.readAsDataURL(file);
    setFormData({ ...formData, [typeOrigin]: file });
  }

  useEffect(() => {
    getMarcas();
    // setMarcaSelecionada(null);
    // setModeloSelecionada(null);
    // setAnoSelecionada(null);
    // setModelos([]);
    // setAnos([]);
  }, [])

  useEffect(() => {
  }, [pictures])

  useEffect(() => {
  }, [formData])

  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    if (fipeValue?.Valor && formData?.valor) {
      setPercentage(ComparePercentageValue(
        ConvertStringToNumber(fipeValue.Valor),
        ConvertStringToNumber(formData.valor))
        )
      setPercentageType(getTypePercentage(
        ConvertStringToNumber(fipeValue.Valor),
        ConvertStringToNumber(formData.valor))
        )
    } else
      setPercentage(undefined)
  }, [fipeValue, formData.valor])


  const handleInput = () => {
    const postData = new FormData();
    postData.append('titulo', formData.titulo);
    postData.append('valor', parseFloat(formData.valor.replace('R$ ', '').replace('.', '').replace(',', '.')).toString());
    postData.append('marca', marcaSelecionada.label);
    postData.append('modelo', modeloSelecionada.label);
    postData.append('ano', anoSelecionada.label);
    postData.append('descricao', formData.descricao);
    postData.append('fotoP', formData.fotoP || '');
    postData.append('fotoLE', formData.fotoLE || '');
    postData.append('fotoLD', formData.fotoLD || '');
    postData.append('fotoTraseira', formData.fotoTraseira || '');
    postData.append('user', getUserId());
    postData.append('porcentagem', percentage?.toString() || '');
    postData.append('porcentagem_tipo', percentageType || '');

    axiosWithAuth.post('/anuncios/', postData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then((response: any) => {
      toast.success("Anúncio cadastrado com sucesso!");
      // navigate('/home');
    }).catch(error => {
      toast.error('Erro ao cadastrar anúncio');

    });
  };


  return (
    <FVR.FormVehicleRegisterWrapper>
      <FVR.Images backgroundimg={pictures}>
        <label htmlFor="fotoP"></label>
        <div className="fotoPrincipal flex" onClick={() => handlePhotoInput(fotoPPhotoRef)} >
          {!pictures.fotoP &&
            <>
              <img src={MiniCar} alt='' className="mb27"></img>
              <div className='text textCenter'>
                <p className="titlePhoto">Foto Principal</p>
                <p className="DescriptionPhoto">Envie a principal foto do carro anunciado</p>
              </div>
            </>}
        </div>
        <input className="hide"
          type="file"
          name="fotoP"
          ref={fotoPPhotoRef}
          onChange={(event) => handleChangeInput(event, "fotoP")}
        />
        <div>
          <div className="itemImage mt27" >
            <div>
              <label htmlFor="fotoLE"></label>
              <div className="photoCar flex photoLeft" onClick={() => handlePhotoInput(fotoLEPhotoRef)}>
                {!pictures.fotoLE && <img src={MiniCar} alt=''></img>}
              </div>
              <input className="hide"
                type="file"
                id="fotoLE"
                name="fotoLE"
                ref={fotoLEPhotoRef}
                onChange={(event) => handleChangeInput(event, "fotoLE")} />
            </div>
            <div className='text'>
              <p>Foto Lateral Esquerda</p>
              <p>Envie uma foto detalhada da lateral esquerda do carro</p>
            </div>
          </div>
          <div className="itemImage" >
            <div>
              <label htmlFor="fotoLD"></label>
              <div className="photoCar flex photoRight" onClick={() => handlePhotoInput(fotoLDPhotoRef)}>
                {!pictures.fotoLD && <img src={MiniCar} alt=''></img>}
              </div>
              <input className="hide"
                type="file"
                id="fotoLD"
                name="fotoLD"
                ref={fotoLDPhotoRef}
                onChange={(event) => handleChangeInput(event, "fotoLD")} />
            </div>
            <div className='text'>
              <p>Foto Lateral Direita</p>
              <p>Envie uma foto detalhada da lateral direita do carro</p>
            </div>
          </div>
          <div className="itemImage" >
            <div>
              <label htmlFor="fotoTraseira"></label>
              <div className="photoCar flex photoBack" onClick={() => handlePhotoInput(fotoTraseiraPhotoRef)}>
                {!pictures.fotoTraseira && <img src={MiniCar} alt=''></img>}
              </div>
              <input className="hide"
                type="file"
                id="fotoTraseira"
                name="fotoTraseira"
                ref={fotoTraseiraPhotoRef}
                onChange={(event) => handleChangeInput(event, "fotoTraseira")} />
            </div>
            <div className='text'>
              <p>Foto Traseira</p>
              <p>Envie uma foto detalhada da traseira do carro</p>
            </div>
          </div>
        </div>
      </FVR.Images>
      <FVR.Details>
        <FVR.DetailsAd>
          <h1>Detalhes do anúncio</h1>
          <FVR.ContentInputDetailsAds>
            <div className='inputDetailsAd'>
              <label>Título do anúncio</label>
              <input name={'titulo'} onChange={handleChange} />
            </div>
          </FVR.ContentInputDetailsAds>
        </FVR.DetailsAd>
        <FVR.DetailsVehicle>
          <h1>Detalhes do veículo</h1>
          <FVR.SpecsWrapper>
            <FVR.ContentSelectDetailsVehicle>
              <div className='selectDetailsVehicle'>
                <label>Marca do carro</label>
                <SelectInput value={marcaSelecionada} options={marcas} isLoading={marcasLoading} onChange={setMarcaSelecionada} />
              </div>
              <div className='selectDetailsVehicle'>
                <label>Modelo</label>
                <SelectInput value={modeloSelecionada} options={modelos} isLoading={modeloLoading} onChange={setModeloSelecionada} />
              </div>
              <div className='selectDetailsVehicle'>
                <label>Ano</label>
                <SelectInput value={anoSelecionada} options={anos} isLoading={anosLoading} onChange={setAnoSelecionada} />
              </div>
            </FVR.ContentSelectDetailsVehicle>
            {fipeValue && <span className={'valor-fipe'}>Valor da fipe <b>{fipeValue?.Valor}</b> | Código fipe: <b>{fipeValue?.CodigoFipe}</b></span>}
          </FVR.SpecsWrapper>
          <FVR.ContentInputDetailsAds>
            <div className='inputDetailsAd'>
              <label>Valor do carro: {percentage && <span className={percentageType == 'acima' ? 'acima' : 'abaixo'}>{percentage} {percentageType} da fipe</span>}</label>
              <NumericFormat
                name={'valor'}
                thousandSeparator="."
                decimalSeparator=","
                prefix="R$ "
                decimalScale={2}
                allowNegative={false}
                onChange={handleChange}
                placeholder="R$ 0,00" />
            </div>
            <p>Valor desejado para o repasse do carro.</p>
          </FVR.ContentInputDetailsAds>
          <FVR.ObservacaoWrapper>
            <label>Observações</label>
            <textarea className='observalInput' name="descricao" onChange={handleChange} rows={5}></textarea>
            <p>Escreva aqui todos os detalhes relevantes sobre o carro que podem afetar o negócio.</p>
          </FVR.ObservacaoWrapper>
        </FVR.DetailsVehicle>
        <Button label="Finalizar Anúncio" variant='primary' onClick={handleInput} />
      </FVR.Details>
    </FVR.FormVehicleRegisterWrapper>
  )
}
