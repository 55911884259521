import * as H from '../Home/Home.styles';
import * as L from '../List/List.styles';
import { Sidebar } from '../../components/Sidebar/Sidebar';
import { Header } from '../../components/Header/Header';
import { Filter } from '../../components/Filter/Filter';
import { Table } from '../../components/Table/Table';
import { useEffect, useState } from 'react';
import Pagination from '../../components/Pagination/Pagination';
import Tag from '../../components/Tag/Tag';
import Modal from '../../components/Modal/Modal';
import PopNewClient from "../../components/PopNewClient/PopNewClient";
import { axiosWithAuth } from '../../services/axiosUtils';
import { getTipoUser } from '../../services/token';

export interface Data {
  id: number,
  nome: string,
  whatsapp: string,
  marca: string,
  modelo: string,
  ano: string,
  ate_valor: string,
  status: string,
  criado_em: string,
}

export type Columns = {
  id: string;
  Header: string;
  accessor: (row: Data) => JSX.Element | string;
}[];

export default function ClientsList() {
  const [data, setData] = useState<Data[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [anounceId, setAnounceId] = useState(0);
  const [type] = useState<'all' | 'finalize'>('all');
  const [tipoUser, setTipoUser] = useState<'lojista' | 'cliente' | 'corretor' | null>(null);

  const confirmar = (id: number) => {
    setAnounceId(id);
    setShowModal(true);
  };

  const cancelar = (id: number) => {
    setAnounceId(id);
  };

  const columns: Columns = [
    {
      id: 'chamado',
      Header: 'Chamado',
      accessor: (row: Data) => (
        <div className='pedidos'>
          <b>#{row.id}</b>
        </div>
      ),
    },
    {
      id: 'status',
      Header: 'Status',
      accessor: (row: Data) => {
        const status = row.status.split('_')[0] || row.status;
        return <Tag type={status as 'finalizado' | 'pendente' | 'aguardando' | 'cancelado'} text={row.status} />;
      },
    },
    {
      id: 'clientes',
      Header: 'Clientes',
      accessor: (row: Data) => (
        <div>
          <span>{row.nome}</span>
        </div>
      ),
    },
    {
      id: 'veiculo',
      Header: 'Veiculo',
      accessor: (row: Data) => (
        <div className='veiculo'>
          <span>{row.modelo} {row.ano}</span>
        </div>
      ),
    },
    {
      id: 'conclusao_do_processo',
      Header: 'Ações',
      accessor: (row: Data) => {
        const status = row.status.split('_')[0] || row.status;
        if (status === 'em' || status === 'finalizado' || (row.status === 'aguardando_vendedor'))
          return <Tag type={status as 'finalizado' | 'pendente' | 'aguardando' | 'cancelado'} text={row.status} />;
        return (
          <div>
            <button onClick={() => confirmar(row.id)}>Concluir</button>
            <button onClick={() => cancelar(row.id)}>Remover</button>
          </div>
        );
      },
    },
  ];

  const getClientsList = () => {
    axiosWithAuth
      .get('/notificacoes/')
      .then(({ data }) => {
        console.log(data)
        setData(
          data.content.map((item: any) => ({
            id: item.id,
            nome: item.nome,
            whatsapp: item.whatsapp,
            marca: item.marca,
            modelo: item.modelo,
            ano: item.ano,
            ate_valor: item.ate_valor,
            status: item.status,
            criado_em: item.criado_em,
          })).sort((a: any, b: any) => a.id - b.id).reverse()
        );
      })
      .catch(err => console.log(err))
      .finally(() => { });
  };

  useEffect(() => {
    setTipoUser(getTipoUser());
  }, []);

  useEffect(() => {
    getClientsList();
  }, [tipoUser]);

  useEffect(() => {
    if (!showModal) {
      if (type === 'all') {
        getClientsList();
      } else {
        // getClientsListSeller();
      }
    }
  }, [showModal]);

  const filterButtonStyling = {
    width: '136px',
  };

  const optionsLojista = [
    {
      label: 'Meu clientes',
      onClick: () => {
        // getClientsList();
        // setType('buy');
      },
    },
    {
      label: 'Finalizados',
      onClick: () => {
        // getClientsListSeller();
        // setType('seller');
      },
    },
  ];

  const optionsCliente = [
    {
      label: 'Notificações',
      onClick: () => {
        // getClientsList();
        // setType('buy');
      },
    },
  ];

  const handleAddClient = () => {
    setShowModal(true)
  }

  return (
    <> {tipoUser &&
      <L.ListWrapper>
        <H.HomeWrapper>
          <H.MainContainer>
            <aside>
              <Sidebar></Sidebar>
            </aside>
            <section>
              <Header
                back={false}
                welcomeText={tipoUser == 'lojista' || tipoUser == 'corretor' ? 'Meus clientes' : 'Alertas'}
                subText={tipoUser == 'lojista' || tipoUser == 'corretor' ? 'Aqui você pode ver todos os seus clientes' : 'Aqui você pode ver todas os alertas'}
              />
              <Filter filterOptions={tipoUser == 'lojista' || tipoUser == 'corretor' ? optionsLojista : optionsCliente} buttonStyles={filterButtonStyling}
                addButton={true} labelButton={tipoUser == 'lojista' || tipoUser == 'corretor' ? 'Novo Cliente' : 'Criar alerta'} handleButton={handleAddClient}
              />
              <hr></hr>
              <H.MainContent>
                {data.length > 0 ? (
                  <Table columns={columns} data={data} />
                ) : (
                  // <Table columns={columns} data={data} />
                  `Você não possui ${tipoUser == 'lojista' || tipoUser == 'corretor' ? 'clientes' : 'alertas'} cadastrados.`
                )}
                <div className='pagination'>
                  <Pagination totalPages={1} actualPage={1} />
                </div>
              </H.MainContent>
            </section>
          </H.MainContainer>
        </H.HomeWrapper>
      </L.ListWrapper>
    }
      <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
        <PopNewClient isCliente={tipoUser == 'cliente'} onClose={() => setShowModal(false)} />
      </Modal>

    </>
  );
}
