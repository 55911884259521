import styled, { css } from 'styled-components';
import { transparentize } from 'polished';

export interface TagProps {
    type: 'finalizado' | 'pendente' | 'aguardando' | 'cancelado' | 'em';
    text: string
}

const THEMES = {
    finalizado: {
        background_color: '#ECFFDF',
        color: '#027a48',
    },
    pendente: {
        background_color: '#FFFAEB',
        color: '#B54708',
    },
    em: {
        background_color: '#FFFAEB',
        color: '#B54708',
    },
    aguardando: {
        background_color: '#f2f4f7',
        color: '#344054',
    },
    cancelado: {
        background_color: '#fef3f2',
        color: '#b4231b',
    },
};


export const TagContainer = styled.div`
  display: inline-block;
  
`;

export const TagData = styled.div<TagProps>`
    display: flex;
    align-items: center;
    gap: 7.5px;
    padding: 5px 10px;
    border-radius: 5px;
    background-color: ${props => THEMES[props.type].background_color};
    color: ${props => THEMES[props.type].color};
    span {
        text-transform: capitalize;
    }
`