import styled from "styled-components";

export const CancelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: 'Inter';
`

export const TopCancel = styled.div`
    display: flex; 
    justify-content: space-between;
    width: 100%;
    margin-bottom:32px; 
`

export const MidCancel = styled.div`
  margin-bottom:20px;

  h1{
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 4px;
  }
  span{
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
  `

export const DetailCancel = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  
  span{
    margin-bottom: 6px;
  }
  .detailsCancel{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    height: 108px;
    background: #FFFFFF;
    border: 1px solid #D0D5DD;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    padding: 12px 14px;
    gap: 8px;
    border-radius: 8px;
    resize: none;
  }

  `

export const BottomCancel = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 12px;
`