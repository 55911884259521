import React from 'react';
import * as S from './Sidebar.styles';
import {ReactComponent as Settings} from '../../assets/svg/settings.svg'
import {ReactComponent as Swap} from '../../assets/svg/swap.svg'
import {ReactComponent as Home} from '../../assets/svg/home.svg'
import {ReactComponent as Avatar} from '../../assets/svg/avatar.svg'
import {ReactComponent as Logo} from '../../assets/svg/logo.svg'
import { useNavigate } from 'react-router-dom';
import {FaUsers,FaHome} from 'react-icons/fa'

export function Sidebar() {
  const navigate = useNavigate();

  const getPath = () => {
    return window.location.pathname;
  }
  return (
    <S.SidebarWrapper>
        <Logo className="logo" onClick={() => navigate('/home')}/>
        <S.SidebarButton>
          <div className={getPath() === '/home' || getPath() == '/new'  ? 'active' : ''} onClick={() => navigate('/home')}><FaHome/></div>
          <div className={getPath() === '/history' ? 'active' : ''} onClick={() => navigate('/history')}><i className="fa-solid fa-shop"/></div>
          <div className={getPath() === '/clients' ? 'active' : ''} onClick={() => navigate('/clients')}><FaUsers/></div>
          <div onClick={() => navigate('')}><i className="fa-solid fa-gear"/></div>
        </S.SidebarButton>
        <div className="avatar"><Avatar/></div>
    </S.SidebarWrapper>
  );
}
