import * as H from '../Home/Home.styles'
import { Sidebar } from '../../components/Sidebar/Sidebar';
import { Header } from '../../components/Header/Header';
import FormVehicleRegister from '../../components/FormVehicleRegister/FormVehicleRegister';

export default function VehicleRegister() {
  return (
    <H.HomeWrapper>
      <H.MainContainer>
        <aside>
          <Sidebar></Sidebar>
        </aside>
        <section>
          <Header back></Header>
          <H.MainContent>
            <FormVehicleRegister></FormVehicleRegister>
          </H.MainContent>
        </section>
      </H.MainContainer>
    </H.HomeWrapper>
  )
}