
import styled from 'styled-components';
import { transparentize } from 'polished';
export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const ArrowWrapper = styled.div<{ isUp: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    color: ${props => (props.isUp ? 'green' : 'red')};
    font-size: 12px;
    border-radius: 100%;
    padding: 5px;
`;

export const ArrowIcon = styled.span<{ isUp: boolean }>`
    color: ${props => (props.isUp ? 'green' : 'red')};
    font-size: 12px;
`;

export const ValueText = styled.span<{ isUp: boolean }>`
    margin-left: 8px;
    /* color: ${props => (props.isUp ? 'green' : 'red')}; */
`;
