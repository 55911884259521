import { styled } from "styled-components";

export const ListWrapper = styled.div `

  section {
    hr {
      width: 100%;
      margin: 20px 0px 17px 0px;
      border-top: 1px solid #EAECF0;
    }
  }
`