import * as S from './StatusBar.styles'
interface StatusBarProps {
    active: boolean,
    title: string,
    description: string
}
export default function StatusBar({ active, title, description }: StatusBarProps) {
    return (
        <S.StatusBarWrapper active={active}>
            <S.StatusBar active={active} />
            <h3>{title}</h3>
            <h4>{description}</h4>
        </S.StatusBarWrapper>
    )

}