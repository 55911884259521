import styled from "styled-components";

export const SellerWrapper = styled.div`
  display:flex;
  margin-top: 20px;
  gap: 24px;
`
export const CarSeller = styled.div`
  display: flex;
  flex-direction: column;

  img{
    margin-bottom: 20px;
    width: 216px;
    height: 119px;
    border-radius: 15px;
    cursor: pointer;
  }
`
export const About = styled.div`
  display: flex;
  flex-direction: column;
 
  img {
    margin-right: 16px;
  }
`
export const AboutIt = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 116px;

  p {
    margin: 0px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #667085;
  }

  span {
    font-family: 'Inter';
    font-style: normal;
  }

  span:first-child{
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #667085;
    border:1px red red;
  }

  span:last-child {
    width: 98px;
    height: 28px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
  }
`
export const SellerDetail = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
  background-color: #FFFFFF;
  border: 1px solid #EAECF0;
  border-radius: 16px;
  padding: 22px 15px;
  max-width: 446px;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
`
export const Classification = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 0px;

  span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #667085;
  }
`
export const AboutSeller = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
`
export const AchievementsSeller = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 19px;

  span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #000000;
  }

  hr:last-child {
    display: none;
  }
`
export const RewardSeller = styled.div`
  display: flex;
  align-items: center;
  gap: 18px;
  
  &:first-child{
    margin-top: 21px;
  };
  
  &:not(:first-child){
    margin-top:13px;
  }
  
  img {
    width: 20px;
    height: 20px;
  }

  span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
  }
`

