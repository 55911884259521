import CarDetails from '../../components/CarDetails/CarDetails';
import * as H from '../Home/Home.styles'
import { Sidebar } from '../../components/Sidebar/Sidebar';
import { Header } from '../../components/Header/Header';
import { useEffect, useState } from 'react';
import SellerDetails from '../../components/SellerDetails/SellerDetails';
import { axiosWithAuth } from '../../services/axiosUtils';
import ReactLoading from 'react-loading';
import { API_IMAGE_URL } from '../../envs';

export default function CardDetails() {

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<any>();
  const [imgPrimary, setImgPrimary] = useState<any>();

  useEffect(() => {
    setIsLoading(true)
    axiosWithAuth.get('/anuncios/' + localStorage.getItem('CardId')).then((res: any) => {
      setData(res.data)
      setImgPrimary(API_IMAGE_URL + res.data.imagens[0])
    }).catch((erro: any) => console.log(erro))
    .finally(() => setIsLoading(false));
  }, [])

  return (
    <H.HomeWrapper>
      <H.MainContainer>
        <aside>
          <Sidebar></Sidebar>
        </aside>
        <section>
          <Header back></Header>
          <H.MainContent>
            <div className={`central ${isLoading ? 'loading' : ''}  column`}>
              {isLoading ? <ReactLoading type="spin" color="black" height={32} width={32} /> : <>
                <CarDetails
                  imagemPrincipal={imgPrimary}
                  marca={data.marca}
                  model={data.modelo}
                  year={data.ano}
                  value={data.valor}
                  history={data.descricao}
                  reservaDisponivel={data.reserva_disponivel}
                />
                <SellerDetails data={data} setImagePrincipal={setImgPrimary} />
              </>
              }
            </div>
          </H.MainContent>
        </section>
      </H.MainContainer>
    </H.HomeWrapper>
  )
}