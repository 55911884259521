import styled from "styled-components";

export const FilterWrapper = styled.div`
  display: flex;
  height: 44px;
  width: auto;
  border: 1px solid #F2F4F7;
  border-radius: 8px;
  padding: 4px;
  background-color: #F9FAFB;
  justify-content: space-between;
`

export const FilterButton = styled.div`
  button {
    width: 101px;
    height: 36px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #667085;
    border: none;
    background-color: #F9FAFB;
    cursor: pointer;
    transition: .2s ease-in-out;
  }

  .active {
    width: 101px;
    height: 36px;
    background: #FFFFFF;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
    border-radius: 6px;
  }
`

export const NovoAnuncioWrapper = styled.div`
  max-width: 200px;
`
