import * as SD from "./SellerDetails.styles"
import avatar from "../../assets/svg/avatar.svg"
import RatingStar from "../../components/RatingStars/RatingStars"
import { API_IMAGE_URL } from "../../envs"
import { useState } from "react";


interface RatingStarsProps {
  data: any;
  setImagePrincipal: any;
}

// interface Conquista {
//   nome: string,
//   icone: string
// }

// interface Imagem {
//   link: string
// }

// interface DetalhesCarro {
//   id: number,
//   titulo: string,
//   descricao: string,
//   marca: string,
//   modelo: string,
//   ano: string,
//   valor: number,
//   thumbnail: string,
//   reserva_disponivel: boolean,
//   user: number,
//   conquistas: Conquista[],
//   rating: number,
//   imagens: Imagem[]
// }

export default function SellerDetails({ data, setImagePrincipal }: RatingStarsProps) {

  const [selectedImage] = useState(data?.imagens[0]);

  const handleImageClick = (img: string) => {
    setImagePrincipal(img);
  };

  return (
    <SD.SellerWrapper>
      <SD.CarSeller>
        <img src={API_IMAGE_URL + "/" + selectedImage} alt="" />
        {data?.imagens?.map((img: string) => (
          <img
            src={API_IMAGE_URL + img}
            alt=""
            onClick={() => handleImageClick(API_IMAGE_URL + "/" + img)}
          />
        ))}
      </SD.CarSeller>
      <SD.SellerDetail>
        <SD.AboutSeller>
          <SD.About>
            <img src={avatar} alt="" />
          </SD.About>
          <SD.AboutIt>
            <p>sobre este</p>
            <span>Revendedor</span>
          </SD.AboutIt>
          <SD.Classification>
            <span>Classificação</span>
            <RatingStar rating={data?.rating} />
          </SD.Classification>
        </SD.AboutSeller>
        <SD.AchievementsSeller>
          <span>Conquistas</span>
          {data?.conquistas?.map((conquista: any) => <>
            <SD.RewardSeller>
              <i className={conquista.icone}></i>
              <span>{conquista.nome}</span>
            </SD.RewardSeller>
            <hr />
          </>)}
        </SD.AchievementsSeller>
      </SD.SellerDetail>
    </SD.SellerWrapper>
  )
}