
import { FaCheckCircle, FaExclamationCircle, FaHourglass, FaTimesCircle } from 'react-icons/fa';
import { TagProps } from './Tag.styles';
import * as T from './Tag.styles';

export default function Tag({ type = 'pendente', text = 'Pendente' }: TagProps) {
    let icon;
  
    switch (type) {
      case 'finalizado':
        icon = <FaCheckCircle />;
        break;
      case 'pendente':
        icon = <FaExclamationCircle />;
        break;
      case 'em':
        icon = <FaExclamationCircle />;
        break;
      case 'aguardando':
        icon = <FaHourglass />;
        break;
      case 'cancelado':
        icon = <FaTimesCircle />;
        break;
      default:
        icon = null;
    }
  
    return (
      <T.TagContainer>
        <T.TagData type={type} text={text}>
        {icon}
        <span>{text.replace('_', ' ')}</span>
        </T.TagData>
      </T.TagContainer>
    );
  }