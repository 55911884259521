import React, { useState } from 'react';
import { useTable, useRowSelect, Column, Cell, Row, HeaderGroup, TableInstance } from 'react-table';
import { TableWrapper } from './Table.styles';

export type Columns = Column<any>[];

export const Table: React.FC<{ columns: Columns; data: any[] }> = ({ columns, data }) => {
  const [selectedRows, setSelectedRows] = useState<number[]>([]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  }: TableInstance<any> = useTable(
    { columns, data },
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: 'selection',
          Header: () => (
            <div>
              <input type="checkbox" />
            </div>
          ),
          Cell: ({ row }: { row: Row<any> }) => (
            <div>
              <input
                type="checkbox"
                checked={selectedRows.includes(row.original.id)}
                onChange={() => handleRowSelection(row.original.id)}
              />
            </div>
          ),
          disableSortBy: true,
        },
        ...columns,
      ]);
    }
  );

  const handleRowSelection = (rowId: number) => {
    if (selectedRows.includes(rowId)) {
      setSelectedRows(selectedRows.filter((id) => id !== rowId));
    } else {
      setSelectedRows([...selectedRows, rowId]);
    }
  };

  return (
    <TableWrapper>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup: HeaderGroup<any>) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row: Row<any>, index: number) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={row.original.id} className={index % 2 === 0 ? 'evenRow' : 'oddRow'}>
                {row.cells.map((cell: Cell<any>, cellIndex: number) => (
                  <td {...cell.getCellProps()}>
                    {cellIndex === 0 ? (
                      <input
                        type="checkbox"
                        checked={selectedRows.includes(row.original.id)}
                        onChange={() => handleRowSelection(row.original.id)}
                      />
                    ) : typeof cell.value === 'string' ? (
                      cell.value.charAt(0).toUpperCase() + cell.value.slice(1)
                    ) : (
                      cell.value
                    )}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </TableWrapper>
  );
};