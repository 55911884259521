import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './pages/Login/Login';
import Register from './pages/Register/Register';
import CardDetails from './pages/CardDetails/CardDetails';
import VehicleRegister from './pages/VehicleRegister/VehicleRegister';
import Home from './pages/Home/Home';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import List from './pages/List/List';
import ClientsList from './pages/ClientsList/ClientsList';


function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route path="/" Component={Login} />
          <Route path="/register" Component={Register} />
          <Route path="/home" Component={Home} />
          <Route path="/details" Component={CardDetails}  />
          <Route path="/new" Component={VehicleRegister}  />
          <Route path="/history" Component={List}  />
          <Route path="/clients" Component={ClientsList}  />
        </Routes>
      </div>
      <ToastContainer />
    </BrowserRouter>
  );
}

export default App;
