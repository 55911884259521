import Star from '../../assets/svg/star.svg'
import FullStar from '../../assets/svg/fullStar.svg'

export interface RatingStarsProps {
  rating: number
}

export default function RatingStars({ rating }: RatingStarsProps) {
  const stars = []
  for (let i = 1; i <= 5; i++) {
    stars.push(<img src={i <= rating ? FullStar : Star} alt="" />)
  }

  return (
    <h2>{stars}</h2>

  )
}