import * as P from './PriceTag.styles';

interface ValueProps {
    value: string;
    percentage: string,
    isUp: boolean;
}

export default function PriceTag({ value, percentage, isUp }: ValueProps) {

    const arrow = isUp ? <>&#8593;</> : <>&#8595;</>;
    const text = isUp ? 'acima' : 'abaixo';

    return (
        <P.Wrapper>
            <P.ValueText isUp={isUp}>{value}</P.ValueText>
            <P.ArrowWrapper isUp={!isUp}>
                {isUp && <>
                    <P.ArrowIcon isUp={!isUp}>{arrow}</P.ArrowIcon>
                    <span>{percentage} {text}</span>
                    <span>da fipe</span>
                </>
                }
                {!isUp && <>
                    <span>{percentage} {text}</span>                    
                    <span>da fipe</span>
                    <P.ArrowIcon isUp={!isUp}>{arrow}</P.ArrowIcon>
                </>
                }
            </P.ArrowWrapper>
        </P.Wrapper>
    );
};
