import * as N from './PopNewClient.styles'
import Check from '../../assets/svg/check.svg'
import Close from '../../assets/svg/close.svg'
import {Input} from '../Input/Input'
import {ChangeEvent, useEffect, useState} from 'react'
import Button from '../Button/Button'
import SelectInput from '../SelectInput/SelectInput'
import {axiosWithAuth} from '../../services/axiosUtils';
import {toast} from 'react-toastify';
import {API_FIPE} from '../../envs';
import {useNavigate} from 'react-router-dom';
import {NumericFormat} from 'react-number-format'

interface PopNewClient {
    onClose: () => void;
    isCliente?: boolean;
}

export default function PopNewClient({onClose, isCliente}: PopNewClient) {
    const [isLoading, setLoading] = useState(false);
    const navigate = useNavigate();

    const [marcaSelecionada, setMarcaSelecionada] = useState<any>();
    const [marcas, setMarcas] = useState<PopNewClient[]>([]);
    const [marcasLoading, setMarcasLoading] = useState<boolean>(false);

    const [modeloSelecionada, setModeloSelecionada] = useState<any>();
    const [modelos, setModelos] = useState<PopNewClient[]>([]);
    const [modeloLoading, setModeloLoading] = useState<boolean>(false);

    const [anoSelecionada, setAnoSelecionada] = useState<any>();
    const [anos, setAnos] = useState<PopNewClient[]>([]);
    const [anosLoading, setAnosLoading] = useState<boolean>(false);

    const [formData, setFormData] = useState({
        nome: '',
        whatsapp: '',
        marca: '',
        modelo: '',
        ano: '',
        ate_valor: 0
    });

    const getMarcas = () => {
        setMarcasLoading(true)
        axiosWithAuth
            .get(`${API_FIPE}/marcas/`)
            .then(({data}) => {
                setMarcas(data.map((marca: any) => ({label: marca.nome, id: marca.codigo})));
            })
            .catch((err) => {
                toast.error('Erro ao buscar marcas');
            }).finally(() => setMarcasLoading(false));
    }

    const getModelos = () => {
        setModeloLoading(true)
        axiosWithAuth
            .get(`${API_FIPE}/marcas/${marcaSelecionada.id}/modelos/`)
            .then(({data}) => {
                setModelos(data.map((modelo: any) => ({label: modelo.nome, id: modelo.codigo})));
            })
            .catch((err) => {
                toast.error('Erro ao buscar modelos');
            }).finally(() => setModeloLoading(false));
    }

    const getAnos = () => {
        setAnosLoading(true)
        axiosWithAuth
            .get(`${API_FIPE}/marcas/${marcaSelecionada.id}/modelos/${modeloSelecionada.id}/anos/`)
            .then(({data}) => {
                setAnos(data.map((modelo: any) => ({label: modelo.nome, id: modelo.codigo})));
            })
            .catch((err) => {
                toast.error('Erro ao buscar modelos');
            }).finally(() => setAnosLoading(false));
    }

    useEffect(() => {
        if (marcaSelecionada && marcaSelecionada.id) {
            getModelos();
        }
    }, [marcaSelecionada])


    useEffect(() => {
        if (modeloSelecionada && modeloSelecionada.id) {
            getAnos();
        }
    }, [modeloSelecionada])

    useEffect(() => {
        getMarcas();
    }, [])

    const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const {name, value} = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const addCliente = () => {
        setLoading(true)
        let data = formData;
        data['marca'] = marcaSelecionada?.label;
        data['modelo'] = modeloSelecionada?.label;
        data['ano'] = anoSelecionada?.label;
        data['ate_valor'] = parseFloat(data?.ate_valor?.toString().replaceAll('R$', '')
            .replaceAll('.', '').replaceAll(',', '.')) || 0.0;
        console.log(data)
        axiosWithAuth.post('notificacoes/', data).then(({data}) => {
            toast.success("Cliente cadastrado com sucesso!")
            onClose();
        }).catch(err => console.log(err))
            .finally(() => setLoading(false))

    }

    return (
        <N.NewClientWrapper>
            <N.TopNewCliente>
                <img src={Check} alt='OK'/>
                <img style={{cursor: 'pointer'}} src={Close} alt="Close" onClick={onClose}/>
            </N.TopNewCliente>
            <N.NewClient>
                <h1>{!isCliente ? 'Novo cliente' : 'Novo Alerta'}</h1>
                <label>Por favor, forneça os dados. <br/> Entraremos em contato assim que tivermos o veículo desejado disponível.</label>
            </N.NewClient>
            {!isCliente &&
            <N.MidNewCliente>
                <div>
                    <label>Nome Completo</label>
                    <Input type='text' placeholder="Ex: Miguel Arcanjo" name="nome"
                           value={formData.nome}
                           onChange={handleChange}/>
                </div>
                <div>
                    <label>Telefone/Whatsapp</label>
                    <Input type='text' placeholder="85 999999999" name="whatsapp"
                           value={formData.whatsapp}
                           onChange={handleChange}/>
                </div>
            </N.MidNewCliente> 
            }
            <N.ContentNewDetailsVehicle>
                <div className='selectDetailsVehicle'>
                    <label>Marca do carro</label>
                    <SelectInput value={marcaSelecionada} options={marcas} isLoading={marcasLoading}
                                 onChange={setMarcaSelecionada}/>
                </div>
                <div className='selectDetailsVehicle'>
                    <label>Modelo</label>
                    <SelectInput value={modeloSelecionada} options={modelos} isLoading={modeloLoading}
                                 onChange={setModeloSelecionada}/>
                </div>
                <div className='selectDetailsVehicle'>
                    <label>Ano</label>
                    <SelectInput value={anoSelecionada} options={anos} isLoading={anosLoading}
                                 onChange={setAnoSelecionada}/>
                </div>
                <div className='selectDetailsVehicle'>
                    <label>Valor máximo </label>
                    <NumericFormat
                        name={'ate_valor'}
                        thousandSeparator="."
                        decimalSeparator=","
                        prefix="R$ "
                        decimalScale={2}
                        allowNegative={false}
                        value={formData.nome}
                        onChange={handleChange}
                        placeholder="R$ 0,00"/>
                </div>
            </N.ContentNewDetailsVehicle>
            <N.BottomNewClient>
                <Button variant="secondary" label="Cancelar" onClick={onClose}/>
                <Button loading={isLoading} onClick={addCliente} variant="primary" label='Salvar'
                        loadingLabel="Salvando..."/>
            </N.BottomNewClient>
        </N.NewClientWrapper>
    )
}
