import * as R from './Reservation.styles'
import Check from '../../assets/svg/check.svg'
import Close from '../../assets/svg/close.svg'
import Button from '../Button/Button'
import { useState } from 'react';



interface ReservationProps {
  onClose: () => void,
  isLoading: boolean,
  handlePagarClick: () => void
}



export default function ConfirmReservation({ onClose, isLoading, handlePagarClick }: ReservationProps) {

  return (
    <R.ReservationWrapper>
      <R.TopReservation>
        <div className='main'>
          <img src={Check} alt='OK' />
        </div>
        <img onClick={onClose} src={Close} alt='fechar' className='absolute' />
      </R.TopReservation>
      <R.MidReservation>
        <h1>Confirmação de reserva</h1>
        <span>Olá<br /><br />
          Antes de comprar o veículo, informamos que é necessário o pagamento de uma taxa de reserva de R$ 1.000,00.<br />
          <br />
          Se a compra for concluída pessoalmente na loja, o valor será enviado ao vendedor. Caso contrário, o valor será estornado integralmente.<br />
          <br />
          Estamos à disposição para esclarecer qualquer dúvida. Agradecemos sua compreensão e aguardamos sua decisão.<br />
          <br />
          Atenciosamente,<br />
          Equipe KlicAutos.</span>
      </R.MidReservation>
      <R.BottomReservation>
        <Button onClick={onClose} variant="secondary" label="Cancelar" />
        <Button loading={isLoading} variant="primary" label="Ir para pagamento" onClick={handlePagarClick} />
      </R.BottomReservation>
    </R.ReservationWrapper>

  )

}