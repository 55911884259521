export const ValueFormatter = (value:number) => {
  return value.toLocaleString('pt-BR', {style:'currency', currency:'BRL'});
}


export const ConvertStringToNumber = (value:string) => {
  return parseFloat(value.replace(/[^0-9,-]+/g, '').replace('.', '').replace(',', '.'));
}

export const ComparePercentageValue = (fipeValue:number, value:number) => {
  const percentage = (value/fipeValue)*100;
  let valor = percentage
  let tipo = 'abaixo'
  
  if (percentage < 100) 
    valor = 100 - percentage
  else {
    tipo = 'acima'
    valor = percentage - 100;
  }
  return `${valor.toFixed(2)}%` ;
}

export const getTypePercentage = (fipeValue:number, value:number) => {
  const percentage = (value/fipeValue)*100;
  let tipo = 'acima'
  if (percentage < 100) 
    tipo = 'abaixo'
    
  return tipo;
}

