import styled from "styled-components";

interface PropsFormVheicle {
  backgroundimg: any
}

export const FormVehicleRegisterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;
  width: 100%;
  max-width: 768px;

  p {
    margin: 0px;
  }

  .hide{
    display: none;
  }
`
export const Images = styled.div<PropsFormVheicle>`
  display: flex;
  flex-direction: column;

  .flex{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  img{
    height: 66px;
    width: 66px;
  }

  .mb27{
    margin-bottom: 27px;
  }

  .textCenter{
    text-align: center;
  }

  .titlePhoto {
    font-family: 'Inter';
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-bottom:7px;
  }
  .descriptionPhoto {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
  }


  .fotoPrincipal {
    margin-bottom: 27px;
    width: 566px;
    height: 312px;
    background: #F2F4F7;
    border-radius: 16px;
    cursor: pointer;
    ${({ backgroundimg }) => { return backgroundimg.fotoP && `background-image: url(${backgroundimg.fotoP}); background-size: cover; background-position: center` }}
  }
  .mt27{
    margin-top:27px;
  }
  .photoCar{
    background: #F2F4F7;
    border-radius: 16px;
    width: 201px;
    height: 116px;
    cursor: pointer;
  }
  .photoLeft{
    ${({ backgroundimg }) => { return backgroundimg.fotoLE && `background-image: url(${backgroundimg.fotoLE}); background-size: cover; background-position: center` }}
  }
    .photoBack{
    ${({ backgroundimg }) => { return backgroundimg.fotoTraseira && `background-image: url(${backgroundimg.fotoTraseira}); background-size: cover; background-position: center` }}
  }
  .photoRight{
    ${({ backgroundimg }) => { return backgroundimg.fotoLD && `background-image: url(${backgroundimg.fotoLD}); background-size: cover; background-position: center` }}
  }
  .itemImage {
    display: flex;
    flex-direction: row;
    margin-bottom: 27px;
    background: #FFFFFF;
    border: 1px solid #EAECF0;
    border-radius: 16px;
    align-items: center;
    gap: 27px;

    input {
      width: 201px;
      height: 116px;
      background: #F2F4F7;
      border-radius: 16px;
    }

    p:first-child {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #000000;
    }

    p:last-child {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #475467;
    }
  }

`
export const Details = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  .buttonSubmit {
    width: 585px;
    height: 48px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    background: #1570EF;
    border: 1px solid #1570EF;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
  }

  label {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #344054;
    margin-bottom: 6px;
  }

  h1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #000000;
    margin-bottom: 14px;
    
  }


  p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #475467;
    margin-top: 8px;
  }

  .select {
    width: 176px;
    padding: 8px;
    background: #FFFFFF;
    border: 1px solid #D0D5DD;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    padding-left: 14px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #667085;
  }
`

export const DetailsAd = styled.div`
  display: flex;
  flex-direction: column;
`

export const ContentInputDetailsAds = styled.div`
  .inputDetailsAd {
    display: flex;
    flex-direction: column;
  }

  .acima {
    color: red;
    font-weight: bold;
  }

  .abaixo {
    color: green;
    font-weight: bold;
  }
  
  p:last-child {
    margin-bottom: 20px;
  }

  input {
    width: auto;
    height: 44px;
    background: #FFFFFF;
    border: 1px solid #D0D5DD;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    text-indent: 20px;
  }
`

export const DetailsVehicle = styled.div`
  display: flex;
  flex-direction: column;
  

  .observalInput {
    width: 585px;
    height: 128px;
    background: #FFFFFF;
    border: 1px solid #D0D5DD;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    padding: 12px 14px;
    text-align: top;
  }
`

export const ContentSelectDetailsVehicle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 20px;
  margin-bottom: 10px;
  /* width: 100%; */

  .selectDetailsVehicle {
    display: flex;
    flex-direction: column;
    /* width: 100%; */
  }

  .selectBrand{
    margin-right: 32px;
  }
`
export const ObservacaoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const SpecsWrapper = styled.div`
  margin-bottom: 16px;
  .valor-fipe {
    font-size: 14px;
    margin: 0;
  }
`